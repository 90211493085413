import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from 'gatsby';
//import Section from 'sections/section';
import MenuData from "../content/Menus.json"

const NotFoundPage = () => {

    const data = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
                description
              }
            }
          }
        `
    )

    const title = data.site.siteMetadata.title;
    const description = data.site.siteMetadata.description;

    const menus = MenuData;

    return (
        <Layout menus={menus}>
            <SEO title={title} description={description} />
            <div>
                <h2 className="bg-white inline-block my-8 p-3">
                    Looks like this page is not available...</h2>
            </div>
        </Layout>
    );
}

export default NotFoundPage;
